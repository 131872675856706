<template>
  <main class="step-page3">
    <Heading :title="'Kiedy potrzebujesz okien?'" />
    <section class="step3 step">
      <div class="step__products">
        <Date data-aos="fade-in" :data-aos-delay="index*250" v-for="(date, index) in dates" :key="index" :date="date" />
      </div>
      <Bottom>
        <Button :path="'step2'" :title="'Wróć'" :classes="'e-button--secondary'" />
        <Button :path="'step4'" :title="'Dalej'" :classes="btnClass" />
      </Bottom>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import Date from "@/components/Date.vue";
import Bottom from "@/components/Bottom.vue";
import Button from "@/components/Button.vue";
import Heading from "@/components/Heading.vue";

export default {
  name: "Step3",
  components: {
    Date,
    Bottom,
    Button,
    Heading
  },
  computed: {
    dates() {
      return this.$store.state.dates;
    },
    btnClass() {
      return Object.keys(this.$store.state.selectedDate).length ? 'e-button--primary' : 'e-button--disabled';
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.checkRoute(to, from)) {
      next();
    }

    if (! Object.keys(this.$store.state.selectedDate).length) {
      return;
    }

    next();
  }
};
</script>

<style scoped lang="scss">
.step3 {}
</style>

